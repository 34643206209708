import styled, { css } from 'styled-components'
import { BPSizes, Colors, Rem } from '../../../commons/Theme'
import { StyledIcon } from '../../Icons/Icon'
import { StyledCTAWrapper, StyledStoreInfoText } from './StoreInfoWindow.style'

export const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  border-bottom: 1px solid ${Colors.grey200};
  margin-bottom: 32px;
  padding-bottom: 32px;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    gap: 28px;
    flex-direction: row;
  }
`

export const StyledIconsWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;

  svg {
    stroke: ${Colors.azure600};
  }
`

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledListItemContent = styled.div`
  flex: 4;
`

export const StyledListItemText = styled(StyledStoreInfoText)`
  margin: 0;
`

export const StyledListItemCTA = styled(StyledCTAWrapper)`
  flex: 4;
  justify-content: flex-end;
  align-items: flex-start;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    justify-content: flex-end;
    align-items: flex-end;
  }

  @media screen and (min-width: ${BPSizes.large}px) {
    flex: 3;
  }
`

export const StyledStoreTimetable = styled.div<{ $open?: boolean }>`
  margin-top: 24px;

  ${StyledIcon} {
    transition: transform 0.2s;
    transform: rotate(${({ $open }) => ($open ? '-180deg' : '0deg')});
  }

  .animate-table {
    transition: max-height 0.3s;
    overflow: hidden;

    ${({ $open }) =>
      $open
        ? css`
            max-height: 300px;
          `
        : css`
            max-height: 0;
          `};
  }

  table {
    margin-top: 8px;

    font-size: ${Rem(14)};
    color: ${Colors.grey700};
    border-spacing: 0;
    border-collapse: collapse;

    td {
      padding-right: 30px;
    }

    td:last-child {
      padding-left: 30px;
    }
  }
`

export const StyledListItemTimetableAccordion = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;

  color: ${Colors.grey900};
  font-size: ${Rem(16)};
  font-weight: 500;
  line-height: 135%;

  transition: color 0.2s;

  &:hover {
    color: ${Colors.grey700};
  }
`
