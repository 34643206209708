import styled, { keyframes } from 'styled-components'
import { BPSizes, Colors, Rem } from '../../../commons/Theme'

export const StyledStoreInfo = styled.div`
  font-family:
    'Life Sans',
    Arial,
    system-ui,
    -apple-system,
    'Segoe UI',
    'Helvetica Neue',
    sans-serif;
  padding: 24px;
  line-height: 130%;
  max-width: 407px;
  display: none;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    display: block;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    line-height: 135%;
  }

  @media screen and (min-width: ${BPSizes.large}px) {
    max-width: 407px;
  }
`

export const StyledStoreInfoTitle = styled.div`
  font-weight: 500;
  font-size: ${Rem(16)};
  color: ${Colors.azure600};
  margin-bottom: 16px;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    font-size: ${Rem(18)};
  }
`
export const StyledStoreInfoSubtitle = styled.div`
  font-size: ${Rem(14)};
  color: ${Colors.grey700};
  margin-bottom: 16px;
`
export const StyledStoreInfoText = styled.div`
  font-size: ${Rem(14)};
  color: ${Colors.grey900};
  a {
    text-decoration: none;
    color: inherit;
  }
`

export const StyledCTAWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const slideUp = keyframes`
  0% {
    transform: translateY(100%)
  }
  100% {
    transform: translateY(0%)
  }
`

export const StyledMobileInfoWindowContent = styled.div`
  background: white;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  padding: 72px 16px 50px;
  animation: ${slideUp} 0.3s forwards;
  position: relative;
`

export const StyledMobileInfoWindow = styled.div`
  display: flex;
  align-items: flex-end;
  position: fixed;
  inset: 0;
  z-index: 100;
  background: #2525218e;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    display: none;
  }
`

export const StyledCloseIcon = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    top: 8px;
    right: 8px;
  }
`

export const StyledStoreInfoHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  margin-bottom: 16px;
`

export const StyledStoreInfoTimetable = styled.div`
  color: ${Colors.grey900};
  font-size: ${Rem(16)};
  font-weight: 500;
  line-height: 135%;
`
