import styled from 'styled-components'
import { BPSizes, Colors, Rem } from '../../../commons/Theme'
export const StyledInfoBox = styled.div`
  margin-bottom: 32px;
`

export const StyledInfoBoxTitle = styled.div`
  font-weight: 600;
  font-size: ${Rem(14)};
  line-height: 130%;
  text-transform: uppercase;
  color: ${Colors.grey600};
  margin-bottom: 4px;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    line-height: 135%;
  }
`

export const StyledInfoBoxMessage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: ${Colors.grey900};

  a {
    color: inherit;
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    line-height: 135%;
  }
`

export const StyledInfoBoxCTA = styled.div`
  margin-top: 8px;
`
