import { GoogleMap, Marker } from '@react-google-maps/api'
import { MapOptions } from '../Mappa.types'
import { snazzyStyle } from '../MappaPrincipale.style'
import { LatLngLiteral } from 'models/type'

const options: MapOptions = {
  clickableIcons: false,
  disableDefaultUI: true,
  draggable: false,
  backgroundColor: '4acfff',
  panControl: false,
  styles: snazzyStyle,
}

export const StaticMap = ({
  center,
  markerIcon,
}: {
  center: LatLngLiteral
  markerIcon: string
}) => {
  return (
    <GoogleMap
      zoom={15}
      center={center}
      options={options}
      mapContainerStyle={{ height: '100%', width: '100%' }}
    >
      <Marker position={center} clickable={false} icon={markerIcon} />
    </GoogleMap>
  )
}
