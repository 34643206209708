export const DirectionsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 7L16 10L13 13"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 15V12C8 10.895 8.895 10 10 10H15.5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.572 22.41L1.591 13.429C0.803 12.641 0.803 11.363 1.591 10.574L10.572 1.59295C11.36 0.804953 12.638 0.804953 13.427 1.59295L22.408 10.574C23.196 11.362 23.196 12.64 22.408 13.429L13.427 22.41C12.638 23.198 11.36 23.198 10.572 22.41V22.41Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
