import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon'
import { LatLngLiteral } from 'models/type'
import { useMemo, useState } from 'react'
import { AtomButton } from '../../atoms/AtomButton'
import { AtomLink } from '../../atoms/AtomLink'
import {
  createICSfile,
  eventDataLayer,
  formatPostDate,
  getGA4PageSezione,
  prepareStringForDataLayer,
  unescapeHTML,
} from '../../commons/utils'
import AnteprimaAvviso from '../../components/AnteprimaAvvisi/components/AnteprimaAvviso'
import { PlusIcon } from '../../components/Icons/PlusIcon'
import { ComunicazioniModel } from '../../hooks/use-comunicazioni-query'
import { BackIcon } from '../Configuratore/components/icons/BackIcon'
import { DirectionsIcon } from '../Icons/DirectionsIcon'
import { Icon } from '../Icons/Icon'
import { StoreData } from './Mappa.types'
import {
  StyledBackIcon,
  StyledBackIconWrapper,
  StyledBadge,
  StyledDettaglioAvvisiWrapper,
  StyledDettaglioLeft,
  StyledDettaglioRight,
  StyledDettaglioSubtitle,
  StyledDettaglioTitle,
  StyledMappaDettaglio,
  StyledMappaDettaglioWrapper,
  StyledMobileOnly,
  StyledServiziList,
  StyledServizio,
  StyledServizioIcon,
  StyledServizioLabel,
  StyledStaticMap,
} from './MappaDettaglio.style'
import { AppuntamentoModal } from './components/AppuntamentoModal'
import { InfoBox } from './components/InfoBox'
import { StyledStoreTimetable } from './components/ListItem.style'
import { StaticMap } from './components/StaticMap'
import { StoreTimetable } from './components/StoreTimetable'

export const MappaDettaglio = ({
  isLoaded,
  store,
  avvisi,
  privacyLink,
}: {
  isLoaded: boolean
  store: StoreData
  avvisi?: ComunicazioniModel[]
  privacyLink?: string | null | undefined
}) => {
  const center: LatLngLiteral = useMemo(
    () => ({ lat: store.lat, lng: store.lng }),
    []
  )
  const [showModal, setShowModal] = useState<boolean>(false)

  function DettagliOrari() {
    return (
      <>
        {!!store.appointment && (
          <>
            <AtomButton
              icon={<ArrowRightIcon />}
              iconPosition="right"
              className="prenota"
              label="Prenota appuntamento"
              onClick={() => setShowModal(true)}
              onDataLayer={() => {
                eventDataLayer({
                  event: 'custom_click',
                  stream: 'web',
                  label: 'prenota_appuntamento',
                  category: 'CTA',
                  position: 'store_locator_dettaglio',
                  urlButtonDestination: store.gatsbyPath?.split('?')[0] ?? '/',
                  sezione: getGA4PageSezione(),
                })
              }}
            />
            <hr />
          </>
        )}
        <InfoBox
          CTA={
            <AtomLink
              icon={<DirectionsIcon />}
              label="Calcola il percorso"
              onDataLayer={() => {
                eventDataLayer({
                  event: 'custom_click',
                  stream: 'web',
                  label: 'calcola_il_percorso',
                  category: 'CTA',
                  position: 'store_locator_dettaglio',
                  urlButtonDestination: store.gatsbyPath?.split('?')[0] ?? '/',
                  sezione: getGA4PageSezione(),
                })
              }}
              target="_blank"
              styleType="ghost"
              to={store.direction}
            />
          }
          messages={[store.address]}
          title="DOVE TROVARCI"
        />
        {store.timetable && store.timetable.length > 0 && (
          <InfoBox title="ORARI D'APERTURA">
            <StyledStoreTimetable style={{ marginTop: 0 }}>
              <StoreTimetable timetable={store.timetable} />
            </StyledStoreTimetable>
          </InfoBox>
        )}
        {store.primaryTel && (
          <InfoBox
            messages={[
              <>
                Tel.{' '}
                <a href={store.hrefPrimaryTel ?? `tel:${store.primaryTel}`}>
                  {store.primaryTel}
                </a>
              </>,
            ]}
            title="CONTATTI"
          />
        )}
      </>
    )
  }

  return (
    <StyledMappaDettaglioWrapper>
      <StyledStaticMap>
        {isLoaded && (
          <StaticMap
            center={center}
            markerIcon={
              store.type_of_store === 'sportelli_commerciali_a2a'
                ? '/assets/icons/map-dark-pin.svg'
                : '/assets/icons/map-simple-pin.svg'
            }
          />
        )}
      </StyledStaticMap>
      <StyledBackIconWrapper>
        <StyledBackIcon to="/assistenza/mappa">
          <Icon
            Icon={BackIcon}
            desktopWidth="24px"
            stroke="inherit"
            width="16px"
          />
        </StyledBackIcon>
      </StyledBackIconWrapper>
      <StyledMappaDettaglio>
        <StyledDettaglioLeft>
          {store.new_opening && (
            <StyledBadge
              $type={
                store.new_opening === 'Prossima apertura'
                  ? 'outline'
                  : 'default'
              }
            >
              {store.new_opening}
            </StyledBadge>
          )}
          <StyledDettaglioTitle>{store.title}</StyledDettaglioTitle>
          {store.note && (
            <StyledDettaglioSubtitle>{store.note}</StyledDettaglioSubtitle>
          )}
          <StyledServiziList>
            {!!store.services?.length &&
              store.services.map((service) => (
                <StyledServizio key={service.id}>
                  {!!service.iconsvg && (
                    <StyledServizioIcon
                      dangerouslySetInnerHTML={{
                        __html: unescapeHTML(service.iconsvg),
                      }}
                    />
                  )}
                  <StyledServizioLabel>{service.label}</StyledServizioLabel>
                </StyledServizio>
              ))}
          </StyledServiziList>
          <StyledMobileOnly>
            <DettagliOrari />
          </StyledMobileOnly>
          {!!avvisi?.length && <hr />}
          {avvisi && (
            <StyledDettaglioAvvisiWrapper>
              {avvisi?.map((item) => (
                <AnteprimaAvviso
                  category={{
                    name:
                      item.relationships.field_categoria_article?.name ?? '',
                    path:
                      item.relationships.field_categoria_article?.path?.alias ??
                      '',
                  }}
                  key={item.id}
                  path={item.path.alias ?? ''}
                  published_at={formatPostDate(
                    item.field_data_pubblicazione ?? item.created
                  )}
                  title={item.title}
                >
                  {item.relationships.field_categoria_article?.name ===
                    'Evento' &&
                    item.field_abilita_calendario_evento === true &&
                    item.field_data_pubblicazione && (
                      <AtomButton
                        icon={<PlusIcon />}
                        iconPosition="right"
                        className={'anteprima-avviso-add-to-calendar-x431f'}
                        label="Aggiungi al calendario"
                        onClick={() => {
                          if (item.field_data_pubblicazione) {
                            createICSfile(
                              item.field_data_pubblicazione,
                              item.field_data_pubblicazione,
                              item.title,
                              null,
                              item.title
                            )
                          }
                        }}
                        onDataLayer={() => {
                          eventDataLayer({
                            event: 'custom_click',
                            eventID: '97',
                            category: 'internal_link',
                            eventAction: 'store_locator-dettaglio_store',
                            label: prepareStringForDataLayer(
                              'aggiungi_al_calendario'
                            ),
                            sezione: getGA4PageSezione(),
                          })
                        }}
                        styleType="ghost"
                      />
                    )}
                </AnteprimaAvviso>
              ))}
            </StyledDettaglioAvvisiWrapper>
          )}
        </StyledDettaglioLeft>
        <StyledDettaglioRight>
          <DettagliOrari />
        </StyledDettaglioRight>
        <AppuntamentoModal
          onClose={() => setShowModal(false)}
          showModal={showModal}
          store={store}
          reload={true}
          privacyLink={privacyLink}
        />
      </StyledMappaDettaglio>
    </StyledMappaDettaglioWrapper>
  )
}
