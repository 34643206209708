import { graphql } from 'gatsby'
import { useLoadScript } from '@react-google-maps/api'
import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import { MappaDettaglio } from 'components/Mappa/MappaDettaglio'
import { StoreData } from 'components/Mappa/Mappa.types'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Layout from 'Layout'
import { Helmet } from 'react-helmet'

function A2AStore(props: A2AStorePageProps) {
  const { a2AStore } = props.data

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_KEY || '',
    libraries: ['places'],
  })

  return (
    <A2ADataContextProvider>
      <Layout
        pageTitle={`${a2AStore.title} a ${a2AStore.comune} | A2A Energia`}
      >
        <Helmet>
          <meta
            name="description"
            content={`Scopri lo Spazio A2A o il nostro Sportello commerciale ${a2AStore.title} a ${a2AStore.comune}. Consulta la mappa, i contatti e tutti i dettagli relativi allo store.`}
          />
        </Helmet>
        <CanonicalUrlComponent url={a2AStore.gatsbyPath} />

        <Breadcrumbs
          links={[
            { uri: '/assistenza', title: 'Assistenza' },
            { uri: '/assistenza/mappa', title: 'Mappa', isActive: true },
          ]}
        />
        <MappaDettaglio
          isLoaded={isLoaded}
          store={a2AStore}
          avvisi={a2AStore.avvisi || []}
        />
      </Layout>
    </A2ADataContextProvider>
  )
}

export default A2AStore

interface A2AStorePageProps {
  data: {
    a2AStore: StoreData
  }
}

export const query = graphql`
  query ($id: String!) {
    a2AStore(id: { eq: $id }) {
      ...A2AStoreFragment
    }
  }
`
