import {
  StyledInfoBox,
  StyledInfoBoxCTA,
  StyledInfoBoxMessage,
  StyledInfoBoxTitle,
} from './InfoBox.style'

export const InfoBox = ({ title, messages, children, CTA }: InfoBoxProps) => {
  return (
    <StyledInfoBox>
      <StyledInfoBoxTitle>{title}</StyledInfoBoxTitle>
      {messages &&
        messages.map((message, i) => (
          <StyledInfoBoxMessage key={i}>{message}</StyledInfoBoxMessage>
        ))}
      {children}
      {CTA && <StyledInfoBoxCTA>{CTA}</StyledInfoBoxCTA>}
    </StyledInfoBox>
  )
}

type InfoBoxProps = {
  title: string
  messages?: React.ReactNode[] | string[] | null | undefined
  children?: React.ReactNode
  CTA?: React.ReactNode
}
