import { useMemo } from 'react'
import { StoreData } from '../Mappa.types'

function isOrarioContinuato(timeString: string | undefined) {
  if (!timeString) return false

  const startHour = Number(timeString?.split('-')[0]?.split(':')[0])
  const endHour = Number(timeString?.split('-')[1]?.split(':')[0])

  return startHour <= 12 && endHour >= 14
}

export const StoreTimetable = ({
  timetable,
}: {
  timetable: StoreData['timetable']
}) => {
  const storeAlwaysOrarioContinuato = useMemo(() => {
    const storeOpenDays = timetable?.filter((time) => time.closed === 0) || []
    const storeOrarioContinuatoDays =
      storeOpenDays?.filter((time) => {
        const morningTime = time.hours.split('/')[0]
        return isOrarioContinuato(morningTime)
      }) || []
    return storeOpenDays.length === storeOrarioContinuatoDays.length
  }, [timetable])

  return (
    <table>
      <tbody>
        {timetable?.map((time) => {
          let morningTime, afternoonTime
          const [time1, time2] = time.hours.split('/')

          if (Number(time1?.split(':')[0]) >= 12) {
            afternoonTime = time1
          } else {
            morningTime = time1
            afternoonTime = time2
          }

          return (
            <tr key={time.day}>
              <td>{time.day}</td>
              <td>
                {time.closed === 1 || !morningTime
                  ? 'chiuso'
                  : morningTime?.replace('-', '/')}
              </td>
              <td>
                {isOrarioContinuato(morningTime) || storeAlwaysOrarioContinuato
                  ? ''
                  : time.closed === 1 || !afternoonTime
                    ? 'chiuso'
                    : afternoonTime?.replace('-', '/')}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
