export const BackIcon = () => {
  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="111"
        y="111"
        width="110"
        height="110"
        rx="55"
        transform="rotate(-180 111 111)"
      />
      <path
        d="M54 42L38 56L54 70"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38 56L74 56"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
