import {
  StyledAnteprimaAvviso,
  StyledAnteprimaAvvisoDetails,
  StyledAnteprimaAvvisoPublishedAt,
  StyledAnteprimaAvvisoTitle,
  StyledAnteprimaAvvisoCategoryLink,
  StyledAnteprimoAvvisoContent,
  StyledAnteprimaAvvisoCategory,
} from './AnteprimaAvviso.style'
import { AnteprimaAvvisoModel } from '../AnteprimaAvvisi.types'

const AnteprimaAvviso = (props: AnteprimaAvvisoModel) => {
  const { title, path, published_at, category, children } = props

  return (
    <StyledAnteprimaAvviso>
      <div className="avviso-inner-x45j0">
        <StyledAnteprimaAvvisoDetails>
          {category.path ? (
            <StyledAnteprimaAvvisoCategoryLink to={category.path}>
              {category.name}
            </StyledAnteprimaAvvisoCategoryLink>
          ) : (
            <StyledAnteprimaAvvisoCategory>
              {category.name}
            </StyledAnteprimaAvvisoCategory>
          )}
          <StyledAnteprimaAvvisoPublishedAt>
            {published_at}
          </StyledAnteprimaAvvisoPublishedAt>
        </StyledAnteprimaAvvisoDetails>

        <StyledAnteprimaAvvisoTitle to={path}>
          {title}
        </StyledAnteprimaAvvisoTitle>

        {children && (
          <StyledAnteprimoAvvisoContent>
            {children}
          </StyledAnteprimoAvvisoContent>
        )}
      </div>
    </StyledAnteprimaAvviso>
  )
}

export default AnteprimaAvviso
